<template>
    <modal ref="modalDetalleImage" titulo="Detalle de la entrega" tamano="modal-lg" icon="aprobar" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="rounded-circle bg-general3 cr-pointer d-middle-center" style="width:20px;height:20px;" @click="$emit('volver')">
                <i class="icon-left text-white" />
            </div>
            <div class="col text-general f-17 f-600">
                Atras
            </div>
        </div>
        <div class="row mx-0 justify-center mt-3">
            <img :src="url" class="obj-cover border br-8" width="575" height="575" />
            <!-- <div class="br-6" style="width: 575px;height: 575px;">
            </div> -->
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: ''
        }
    },
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalDetalleImage.toggle();
        }
    }
}
</script>